import React, { Component } from 'react'
import { css } from 'theme-ui'

export default class Filters extends Component {
  constructor(props) {
    super(props)
    this.activateChainItem = this.activateChainItem.bind(this)
    this.activateTypeItem = this.activateTypeItem.bind(this)
    this.state = {
      selectedChain: 'allchains',
      selectedType: 'alltypes'
    }
  }

  activateChainItem(e) {
    e.target.id = `active`
    document
      .getElementsByClassName(`item ${this.state.selectedChain}`)[0]
      ?.setAttribute('id', '')
    this.setState({ selectedChain: e.target.attributes.value.value })
    this.props.onSelectChain(e.target.attributes.value.value)
  }

  activateTypeItem(e) {
    e.target.id = `active`
    document
      .getElementsByClassName(`item ${this.state.selectedType}`)[0]
      ?.setAttribute('id', '')
    this.setState({ selectedType: e.target.attributes.value.value })
    this.props.onSelectType(e.target.attributes.value.value)
  }

  render() {
    return (
      <div css={css(t => t.filters)}>
        <chainFilters
          className='chainFilters'
          css={css(t => t.styles.chainFilters)}
        >
          <div className='chains'>
            <item
              className='item allchains'
              id='active'
              value='allchains'
              onClick={this.activateChainItem}
            >
              所有网络
            </item>
            <item
              className='item ethereum'
              value='ethereum'
              onClick={this.activateChainItem}
            >
              Ethereum
            </item>
            <item
              className='item solana'
              value='solana'
              onClick={this.activateChainItem}
            >
              Solana
            </item>
            {/* <item
              className='item eos'
              value='eos'
              onClick={this.activateChainItem}
            >
              EOS
            </item> */}
            <item
              className='item bsc'
              value='bsc'
              onClick={this.activateChainItem}
            >
              BSC
            </item>
            <item
              className='item bsc'
              value='bsc'
              onClick={this.activateChainItem}
            >
              Layer 1
            </item>
            <item
              className='item okexchain'
              value='okexchain'
              onClick={this.activateChainItem}
            >
              OKExChain
            </item>
            <item
              className='item heco'
              value='heco'
              onClick={this.activateChainItem}
            >
              HECO
            </item>
            <item
              className='item fantom'
              value='fantom'
              onClick={this.activateChainItem}
            >
              Fantom
            </item>
            <item
              className='item cronos'
              value='cronos'
              onClick={this.activateChainItem}
            >
              Cronos
            </item>
          </div>
        </chainFilters>
        <typeFilters
          className='typeFilters'
          css={css(t => t.styles.typeFilters)}
        >
          <div className='types'>
            <item
              className='item alltypes'
              value='alltypes'
              id='active'
              onClick={this.activateTypeItem}
            >
              所有标签
            </item>
            <item
              className='item dex'
              value='dex'
              onClick={this.activateTypeItem}
            >
              DEX
            </item>
            <item
              className='item amm'
              value='amm'
              onClick={this.activateTypeItem}
            >
              AMM
            </item>
            <item
              className='item lending'
              value='lending'
              onClick={this.activateTypeItem}
            >
              Lending
            </item>
            <item
              className='item yield-aggregator'
              value='yield-aggregator'
              onClick={this.activateTypeItem}
            >
              Yield-Aggregator
            </item>
            <item
              className='item minting'
              value='minting'
              onClick={this.activateTypeItem}
            >
              Minting
            </item>
            <item
              className='item layer2'
              value='layer2'
              onClick={this.activateTypeItem}
            >
              Layer 2
            </item>
            <item
              className='item derivatives'
              value='derivatives'
              onClick={this.activateTypeItem}
            >
              Derivatives
            </item>
            <item
              className='item gamefi'
              value='gamefi'
              onClick={this.activateTypeItem}
            >
              GameFi
            </item>
          </div>
        </typeFilters>
      </div>
    )
  }
}
