import React, { Component, useState, useEffect } from 'react'
import { Pagination } from 'react-bootstrap'
import PropTypes from 'prop-types'
import './Pagination.styles.css'
import { css } from 'theme-ui'

let prevData

function TablePagination({
  data,
  RenderComponent,
  title,
  pageLimit,
  dataLimit
}) {
  let [pages] = useState(Math.round(data.length / dataLimit));
  const [currentPage, setCurrentPage] = useState(1);
  console.log("pages ", [pages]);
  console.log("currentpage ", currentPage);

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
    // not yet implemented
  }

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage((page) => page - 1);
    }
    // not yet implemented
  }

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
    // not yet implemented
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    return data.slice(startIndex, endIndex);
    // not yet implemented
  };

  const getPaginationGroup = () => {
    if (pages < pageLimit) {
      pageLimit = pages;
    }
    let pgLimit = pageLimit;
    console.log("pages 2", pages);
    console.log("pglimit page", pgLimit);
    const min = pages - currentPage;
    console.log("min ", min);
    if (min < pgLimit && currentPage === pgLimit + 1) {
      pgLimit = min + 1;
    }
    let start = Math.floor((currentPage - 1) / pgLimit) * pgLimit;
    return new Array(pgLimit).fill().map((_, idx) => start + idx + 1);
    // not yet implemented
  };

  if (data !== prevData && currentPage !== 1) {
    setCurrentPage(1);
    prevData = data;
    pages =
      Math.round(data.length / dataLimit) === 0
        ? Math.round(data.length / dataLimit)
        : Math.round(data.length / dataLimit);
  } else {
    prevData = data;
    pages =
      data.length / dataLimit > 0 ? Math.ceil(data.length / dataLimit) : 0;
  }

  return (
    <tablePaginated css={css(t => t.styles.tablePaginated)}>
      {/* <h1>{title}</h1> */}

      {/* show the posts, 10 posts at a time */}
      <div className='dataContainer'>
        <RenderComponent data={getPaginatedData()} />
      </div>

      {/* show the pagiantion
        it consists of next and previous buttons
        along with page numbers, in our case, 5 page
        numbers at a time
    */}
      {pages !== 0 && (
        <div className='pagination'>
          {/* previous button */}
          <button
            onClick={goToPreviousPage}
            className={`prev ${currentPage === 1 ? 'disabled' : ''}`}
          >
            <svg
              viewBox='64 64 896 896'
              focusable='false'
              class=''
              data-icon='left'
              width='1em'
              height='1em'
              fill='currentColor'
              aria-hidden='true'
            >
              <path d='M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 0 0 0 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z'></path>
            </svg>
          </button>

          {/* show page numbers */}
          {getPaginationGroup().map((item, index) => (
            <button
              key={index}
              onClick={changePage}
              className={`paginationItem ${
                currentPage === item ? 'active' : null
              }`}
            >
              <span>{item}</span>
            </button>
          ))}

          {/* next button */}
          <button
            onClick={goToNextPage}
            className={`next ${currentPage === pages ? 'disabled' : ''}`}
          >
            <svg
              viewBox='64 64 896 896'
              focusable='false'
              class=''
              data-icon='right'
              width='1em'
              height='1em'
              fill='currentColor'
              aria-hidden='true'
            >
              <path d='M765.7 486.8L314.9 134.7A7.97 7.97 0 0 0 302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 0 0 0-50.4z'></path>
            </svg>
          </button>
        </div>
      )}
    </tablePaginated>
  )
}

export default TablePagination

// export default class Pagination extends Component {
//     constructor(props) {
//         super(props)
//         const { data, RenderComponent, title, pageLimit, dataLimit } = this.props
//         this.state = {
//           data,
//           RenderComponent,
//           title,
//           pageLimit,
//           dataLimit
//         }
//       }

//      goToNextPage() {
//         setCurrentPage((page) => page + 1);
//         // not yet implemented
//      }

//       goToPreviousPage() {
//         // not yet implemented
//      }

//       changePage(event) {
//         // not yet implemented
//      }

//       getPaginatedData = () => {
//         // not yet implemented
//      };

//       getPaginationGroup = () => {
//         // not yet implemented
//      };
//   render(){
//     const { data, RenderComponent, title, pageLimit, dataLimit } = this.state
//     const [pages] = useState(Math.round(data.length / dataLimit));
//     const [currentPage, setCurrentPage] = useState(1);

//     return (
//         <div>test</div>
//     )
//   }
// }
