import React from 'react'
import { Heading } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  heading: {
    span: {
      color: `omegaDark`
    }
  }
}

export default () => (
  <Heading variant='h2' sx={styles.heading} style={{ letterSpacing: '0.8px' }}>
    聚焦热点DeFi，掌握教程玩法
    <br />
    <span>捕获DeFi价值,</span>
    <br />
    defi.to助你从金融小白成长为高阶玩家
  </Heading>
)
