import React from 'react'
import { Layout, Stack, Hero, Main, Sidebar } from '@layout'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import Table from '@components/Table'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import HeroComponent from '../components/Hero/Hero'
// import useStrapiProjects from '@helpers-blog/useStrapiProjects'

const Posts = ({
  data: { posts = {}, featuredPosts = {}, recentPosts = {}, projects = {} },
  ...props
}) => {
  const { pageContext: { services = {} } = {} } = props
  const featuredIDs = featuredPosts.nodes.slice(0, 3).map(item => {
    if (item.id) {
      return item.id
    }
  })
  const postsNoFeature = recentPosts.nodes.map(item => {
    for (let i in featuredIDs) {
      if (item.id === featuredIDs[i]) {
        return undefined
      }
    }
    return item
  })
  // console.log(
  //   'postsnofeature ',
  //   postsNoFeature.filter(function (x) {
  //     return x !== undefined
  //   })
  // )
  return (
    <Layout {...props} locale='zh-CN'>
      <Seo
        title='Defi.to'
        description='一览热门DeFi项目教程，抓住未来DeFi财富趋势，实现更高DeFi聚合收益'
      />
      {/* <Divider /> */}
      <div style={{ marginTop: '3rem', marginBottom: '3rem' }} />
      <Stack>
        <Table data={projects.nodes} />
      </Stack>
      {/* {console.log('recentPosts.nodes', recentPosts.nodes)} */}
      <Stack effectProps={{ effect: false }}>
        {/* <CardList
          nodes={featuredPosts.nodes}
          limit={3}
          variant='vertical-cover'
          title='Featured this month'
          slider
          columns={[1, 2, 3, 3]}
        /> */}
        <CardList
          nodes={featuredPosts.nodes}
          limit={3}
          columns={[1, 2, 3]}
          variant={['horizontal-md', 'vertical-cover']}
          title='编辑最新推荐'
          loading='eager'
          omitCategory
        />
      </Stack>
      <Divider space={2} />
      <Stack>
        <CardList
          nodes={postsNoFeature.filter(function (x) {
            return x !== undefined
          })}
          limit={6}
          // skip={3}
          columns={[1, 2, 3]}
          variant={['horizontal-md', 'horizontal-aside']}
          loading='eager'
        />
      </Stack>
      <Divider />
      <Hero sx={{ bg: `headerBg` }}>
        <HeroComponent />
      </Hero>
      {/* <Divider /> */}
      {/* <Divider space={5} /> */}
      <Stack>
        <Main>{services.mailchimp && <NewsletterExpanded />}</Main>
      </Stack>
    </Layout>
  )
}

export default Posts

// {posts.group.length &&
//   posts.group.map((group, index) => (
//     <React.Fragment key={`${group.categoryName}.list`}>
//       {index % 2 === 0 ? (
//         <Stack
//           title={group.categoryName}
//           titleLink={group.nodes[0].category.slug}
//         >
//           <Main>
//             <CardList
//               nodes={group.nodes}
//               limit={2}
//               variant={['horizontal-md', 'horizontal']}
//             />
//           </Main>
//           <Sidebar sx={{ pl: 3, display: [null, `flex`] }}>
//             <CardList
//               nodes={group.nodes}
//               limit={1}
//               skip={2}
//               variant={['horizontal-md', 'vertical']}
//             />
//           </Sidebar>
//         </Stack>
//       ) : (
//         <Stack
//           effectProps={{ effect: 'fadeInLeft' }}
//           title={group.categoryName}
//           titleLink={group.nodes[0].category.slug}
//         >
//           <Main>
//             <CardList
//               nodes={group.nodes}
//               limit={1}
//               variant={['horizontal-md', 'horizontal']}
//             />
//             <Divider space={2} />
//             <CardList
//               nodes={group.nodes}
//               limit={2}
//               skip={2}
//               columns={[1, 2]}
//               variant={['horizontal-md', 'horizontal-aside']}
//               omitMedia
//               omitCategory
//             />
//             <Divider space={2} />
//             <CardList
//               nodes={group.nodes}
//               limit={2}
//               skip={4}
//               columns={[1, 2]}
//               variant={['horizontal-md', 'horizontal-aside']}
//               mediaType='icon'
//               omitCategory
//             />
//           </Main>
//           <Sidebar sx={{ pl: 3, display: [null, `flex`] }}>
//             <CardList
//               nodes={group.nodes}
//               limit={1}
//               skip={1}
//               variant={['horizontal-md', 'vertical']}
//             />
//           </Sidebar>
//         </Stack>
//       )}
//       {index !== posts.group.length - 1 && <Divider />}
//     </React.Fragment>
//   ))}
