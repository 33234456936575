import React, { Component } from 'react'
import Filters from './Filters'
import { css } from 'theme-ui'
import pancakeswap from '../../assets/pancakeswap.png'
import curve from '../../assets/curve.png'
import uniswap from '../../assets/uniswap.png'
import compound from '../../assets/compound.png'
import mercurial from '../../assets/mercurial.png'
import hfione from '../../assets/hfione.png'
import cherryswap from '../../assets/cherryswap.png'
import yfi from '../../assets/yfi.png'
import bsc from '../../assets/bsc.svg'
import eth from '../../assets/eth.svg'
import heco from '../../assets/heco.svg'
import okexchain from '../../assets/okexchain.svg'
import solana from '../../assets/solana.svg'
import axios from 'axios'
import TablePagination from './Pagination'
// import useStrapiProjects from '@helpers-blog/useStrapiProjects'
import colors from '../../../flow-ui-theme/src/theme/colors'

function prettyNumbers(x) {
  if (isNaN(x)) return x

  if (x < 9999) {
    return x
  }

  if (x < 1000000) {
    return Math.round(x / 1000) + 'K'
  }
  if (x < 10000000) {
    return (x / 1000000).toFixed(2) + 'M'
  }

  if (x < 1000000000) {
    return Math.round(x / 1000000) + 'M'
  }

  if (x < 1000000000000) {
    return (x / 1000000000).toFixed(2) + 'B'
  }

  return '1T+'
}

const columns = ['Name', 'Tag', 'Chain', 'Guide']

const chainImages = {
  ethereum: eth,
  bsc,
  eos: '',
  heco,
  solana,
  okexchain
}

let data = [
  {
    image: curve,
    title: 'Curve',
    tags: ['DEX'],
    chain: 'Ethereum',
    deposits: '-',
    guide: 'Available',
    url: 'cn/curve-tutorial',
    id: 'curve'
  },
  {
    image: uniswap,
    title: 'Uniswap V3',
    tags: ['DEX', 'AMM'],
    chain: 'Ethereum',
    deposits: '-',
    guide: 'Available',
    url: 'cn/uniswap-v3-tutorial',
    id: 'uniswap-v3'
  },
  {
    image: compound,
    title: 'Compound',
    tags: ['Lending'],
    chain: 'Ethereum',
    deposits: '-',
    guide: 'Soon',
    id: 'compound'
  },
  {
    image: pancakeswap,
    title: 'PancakeSwap',
    tags: ['DEX', 'AMM'],
    chain: 'BSC',
    deposits: '-',
    guide: 'Soon',
    id: 'pancakeswap'
  },
  {
    image: mercurial,
    title: 'Mercurial Finance',
    tags: ['DEX'],
    chain: 'Solana',
    deposits: '-',
    guide: 'Available',
    url: 'cn/mercurial-tutorial-solana',
    id: 'mercurial-finance'
  },
  {
    image: cherryswap,
    title: 'Cherry Swap',
    tags: ['DEX', 'AMM'],
    chain: 'OKExChain',
    deposits: '-',
    guide: 'Available',
    url: 'cn/cherryswap-tutorial',
    id: 'okt_cherryswap',
    debank: true
  },
  {
    image: hfione,
    title: 'HFI.one',
    tags: ['Yield-Aggregator'],
    chain: 'HECO',
    deposits: '-',
    guide: 'Available',
    url: 'cn/hfi-tutorial-heco',
    id: 'heco_hfione',
    debank: true
  },
  {
    image: yfi,
    title: 'Yearn Finance',
    tags: ['Yield-Aggregator'],
    chain: 'Ethereum',
    deposits: '-',
    guide: 'Available',
    url: 'cn/yearn-tutorial-yfi-ethereum',
    id: 'yearn-finance'
  }
]

const options = {
  //   filterType: 'textField'
  selectableRows: 'none'
}

export default class Table extends Component {
  constructor(props) {
    super(props)
    this.buildTags = this.buildTags.bind(this)
    this.buildTable = this.buildTable.bind(this)
    this.handleChainSelect = this.handleChainSelect.bind(this)
    this.handleTypeSelect = this.handleTypeSelect.bind(this)
    this.compileData = this.compileData.bind(this)
    this.createTable = this.createTable.bind(this)
    this.state = {
      selectedChain: 'allchains',
      selectedType: 'alltypes',
      newTable: ['none'],
      tableData: this.compileData(this.props.data)
    }
  }

  async componentWillMount() {
    // console.log('useStrapiProjects ', useStrapiProjects())
    if (typeof window !== 'undefined') {
      const timeNow = new Date().getTime()
      const timeExpiration = timeNow + 1000 * 3600 * 6 // 6 h
      let dataAPIs
      let localData = window.localStorage.getItem(`debank-llama`)
      if (
        localData === null ||
        window.localStorage.getItem(`timeout:debank-llama`) < timeNow
      ) {
        const api = await axios({
          method: 'get',
          url: 'https://monitor-api.eosx.io/defistats'
        })
        dataAPIs = { debank: api.data.debank, llama: api.data.llama }
        window.localStorage.setItem(`timeout:debank-llama`, timeExpiration)
        window.localStorage.setItem(`debank-llama`, JSON.stringify(dataAPIs))
      } else {
        dataAPIs = JSON.parse(window.localStorage.getItem(`debank-llama`))
      }
      let res = dataAPIs.debank
      let res2 = dataAPIs.llama

      for (let i in data) {
        const findID = data[i].id
        if (data[i].debank !== true) {
          const userCount = res2.filter(array => array.slug === findID)
          if (userCount[0]) {
            data[i].deposits = `$ ${prettyNumbers(userCount[0].tvl)}`
          }
        } else {
          const userCount = res?.data?.filter(array => array.id === findID)
          data[i].deposits = userCount[0]
            ? `$ ${
                userCount[0].total_user_usd !== null ||
                userCount[0].total_user_usd !== 0
                  ? prettyNumbers(userCount[0].total_user_usd)
                  : prettyNumbers(userCount[0].tvl)
              }`
            : 0
        }
      }
      this.setState({ tableData: data })
    }
  }

  compileData(data2) {
    let newArray = []
    for (let i in data2) {
      const newDataObject = {
        image: data2[i].thumbnail.ImageSharp_small.images.sources[0].srcSet,
        title: data2[i].Title,
        tags: data2[i].tags,
        chain: data2[i].category.name,
        debank: data2[i].debank,
        deposits: '-',
        guide: data2[i].slug ? 'Available' : 'Soon',
        url: data2[i].slug,
        id: data2[i].APIName,
        chainImg:
          data2[i]?.category?.icon?.ImageSharp_small?.images?.sources[0]?.srcSet
      }
      newArray.push(newDataObject)
    }

    data = newArray
    return data
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.selectedChain !== prevState.selectedChain ||
      this.state.selectedType !== prevState.selectedType
    ) {
      const newTable = this.buildTable(
        this.state.selectedChain,
        this.state.selectedType
      )
      this.setState({ newTable })
    }
  }

  handleChainSelect(chain) {
    this.setState({ selectedChain: chain })
  }

  handleTypeSelect(type) {
    this.setState({ selectedType: type })
  }

  buildTags(tags) {
    let htmlTags = ''
    for (let j in tags) {
      htmlTags += `
          <tags
          class="tag"
            style="
              padding: 4px 9px;
              font-weight: 700;
              font-size: 12px;
              transform: scale(0.9166);
              margin-bottom: 4px;
              transform-origin: left top;
              margin-right: 5px;
              line-height: initial;
              border-radius:4px;
              z-index:1
            "
            onclick=${`window.location="https://defi.to/cn/tag/${tags[
              j
            ].toLowerCase()}/"`}
          >
          
            ${tags[j]}

          </tags>
          `
    }
    return htmlTags
  }

  createTable(props) {
    const { data } = props
    let htmlElements = ''
    for (let i in data) {
      const chainName = data[i].chain

      const elementHtml = ` <a class=${
        data[i].url ? 'linkAvailable' : 'link-not-available'
      } href=${
        data[i].url ? `https://defi.to${data[i].url}` : 'javascript:void(0);'
      } >    <tableRow class='tableRow'>


      <span style="width: 290px; text-align: left">
        <div title=${data[i].title} class='labelWithIcon'>
          <div class='projectIcon' style="width: 30px">
            <div style= "left: 0px">
              <img
                src=${data[i].image}
                alt
                style="width: 24px; height: 24px; border-radius: 5px"
              ></img>
            </div>
            <img style="right: 3px;" class='chainImage' src=${
              data[i].chainImg || chainImages[chainName.toLowerCase()]
            }></img>
          </div>
          <projectTitle class='projectTitle'>${data[i].title}</projectTitle>
        </div>
      </span>
      <span class="project-tag" style="width: 220px; text-align: left" > <div class='tagsList'>
      ${this.buildTags(data[i].tags)}
      </div></span>
      <span class="project-chain" style="width: 150px; text-align: left">${
        data[i].chain
      }</span>

      <span style="width: 110px; font-weight: 600; text-align: right; color: ${
        data[i].debank ? '#f98d6f' : 'heading'
      }">${data[i].deposits}</span>
      <span class="guide-tag" style="width: 110px; text-align: right">${
        data[i].guide
      }</span>
      
    </tableRow>
    </a>
 `
      htmlElements += elementHtml
    }

    return (
      <fullTable
        className='fullTable'
        css={css(t => t.styles.fullTable)}
        dangerouslySetInnerHTML={{
          __html: htmlElements
        }}
      ></fullTable>
    )
  }

  buildTable(filteredChain = 'allchains', filteredType = 'alltypes') {
    // this.setState({ resetPagination: false })
    let filteredArray = []
    if (filteredChain !== 'allchains' && filteredType !== 'alltypes') {
      filteredArray = this.state.tableData.filter(
        row =>
          row.chain.toLowerCase() === filteredChain &&
          row.tags.filter(tag => tag.toLowerCase() === filteredType).length > 0
      )
    } else if (filteredChain !== 'allchains' && filteredType === 'alltypes') {
      filteredArray = this.state.tableData.filter(
        row => row.chain.toLowerCase() === filteredChain
      )
    } else if (filteredChain === 'allchains' && filteredType !== 'alltypes') {
      filteredArray = this.state.tableData.filter(
        row =>
          row.tags.filter(tag => tag.toLowerCase() === filteredType).length > 0
      )
    } else {
      filteredArray = this.state.tableData
    }

    return (
      <TablePagination
        data={filteredArray}
        RenderComponent={this.createTable}
        title='Projects'
        pageLimit={5}
        dataLimit={6}
      />
    )
  }

  render() {
    return (
      <div css={css(t => t.table)}>
        <div style={{ marginTop: '2rem', marginBottom: '2rem' }} />
        <Filters
          onSelectChain={this.handleChainSelect}
          onSelectType={this.handleTypeSelect}
        />
        <tableHeader
          className='flexRow tableHeader'
          css={css(t => t.styles.tableHeader)}
          style={{ textTransform: 'uppercase' }}
        >
          <span style={{ width: '290px', textAlign: 'left' }}>名称</span>
          <span
            className='project-tag'
            style={{ width: '220px', textAlign: 'left' }}
          >
            标签
          </span>
          <span
            className='project-chain'
            style={{ width: '150px', textAlign: 'left' }}
          >
            所属网络
          </span>
          <span style={{ width: '110px', textAlign: 'right' }}>
            {/* <span className='SortIcon' style={{ display: 'inline-flex' }}/> */}
            <span style={{ color: 'heading ' }}>TVL</span>/
            <span style={{ color: '#f98d6f' }}>存款</span>
          </span>
          <span
            className='availability'
            style={{ width: '110px', textAlign: 'right' }}
          >
            {/* <span className='SortIcon' style={{ display: 'inline-flex' }}/> */}
            指南
          </span>
        </tableHeader>
        {this.state.newTable[0] !== 'none'
          ? this.state.newTable
          : this.buildTable()}
        {/* <tableFooter>
          <ul className='pagination mini' unselectable='unselectable'>
            <li title='previous page' tabIndex='0' className='pagination-disabled pagination-prev'></li>
          </ul>
        </tableFooter> */}
      </div>
    )
  }
}

// {/* full row */}
//         {/* {this.buildTable()} */}
//         <div className='tableRow'>
//           {/* name */}
//           <span style={{ width: '220px', textAlign: 'left' }}>
//             <div title='Curve' className='labelWithIcon'>
//               <div className='projectIcon' style={{ width: '30px' }}>
//                 <div style={{ left: '0px' }}>
//                   <img
//                     src='https://static.debank.com/image/project/logo_url/curve/aa991be165e771cff87ae61e2a61ef68.png'
//                     alt
//                     style={{ width: '24px', height: '24px' }}
//                   ></img>
//                 </div>
//               </div>
//               <div className='projectTitle'>Curve</div>
//             </div>
//           </span>
//           {/* tags */}
//           <span style={{ width: '220px', textAlign: 'left' }}>
//             <div className='tagsList'>
//               {/* tag */}
//               <div
//                 style={{
//                   padding: '4px 9px',
//                   fontWeight: '700',
//                   fontSize: '12px',
//                   transform: 'scale(0.9166)',
//                   marginBottom: '4px',
//                   transformOrigin: 'left top',
//                   marginRight: '5px',
//                   color: 'rgb(97, 107, 132)',
//                   background: 'rgba(106, 117, 202, 0.1)',
//                   cursor: 'initial',
//                   linHeight: 'initial',
//                   borderRadius: '4px'
//                 }}
//               >
//                 DEX
//               </div>
//             </div>
//           </span>
//           {/* chain */}
//           <span style={{ width: '220px', textAlign: 'left' }}>Ethereum</span>
//           {/* deposits */}
//           <span style={{ width: '110px', textAlign: 'right' }}>$8.7B</span>
//           <span style={{ width: '110px', textAlign: 'right' }}>Soon</span>
//         </div>
//         {/* second row */}
//         <div className='tableRow'>
//           {/* name */}
//           <span style={{ width: '220px', textAlign: 'left' }}>
//             <div title='Curve' className='labelWithIcon'>
//               <div className='projectIcon' style={{ width: '30px' }}>
//                 <div style={{ left: '0px' }}>
//                   <img
//                     src='https://static.debank.com/image/project/logo_url/uniswap3/87a541b3b83b041c8d12119e5a0d19f0.png'
//                     alt
//                     style={{ width: '24px', height: '24px' }}
//                   ></img>
//                 </div>
//               </div>
//               <div className='projectTitle'>Uniswap V3</div>
//             </div>
//           </span>
//           {/* tags */}
//           <span style={{ width: '220px', textAlign: 'left' }}>
//             <div className='tagsList'>
//               {/* tag */}
//               <div
//                 style={{
//                   padding: '4px 9px',
//                   fontWeight: '700',
//                   fontSize: '12px',
//                   transform: 'scale(0.9166)',
//                   marginBottom: '4px',
//                   transformOrigin: 'left top',
//                   marginRight: '5px',
//                   color: 'rgb(97, 107, 132)',
//                   background: 'rgba(106, 117, 202, 0.1)',
//                   cursor: 'initial',
//                   linHeight: 'initial',
//                   borderRadius: '4px'
//                 }}
//               >
//                 DEX
//               </div>
//               <div
//                 style={{
//                   padding: '4px 9px',
//                   fontWeight: '700',
//                   fontSize: '12px',
//                   transform: 'scale(0.9166)',
//                   marginBottom: '4px',
//                   transformOrigin: 'left top',
//                   marginRight: '5px',
//                   color: 'rgb(97, 107, 132)',
//                   background: 'rgba(106, 117, 202, 0.1)',
//                   cursor: 'initial',
//                   linHeight: 'initial',
//                   borderRadius: '4px'
//                 }}
//               >
//                 AMM
//               </div>
//             </div>
//           </span>
//           {/* chain */}
//           <span style={{ width: '220px', textAlign: 'left' }}>Ethereum</span>
//           {/* deposits */}
//           <span style={{ width: '110px', textAlign: 'right' }}>$8.7B</span>
//           <span style={{ width: '110px', textAlign: 'right' }}>
//             Available
//           </span>
//         </div>
