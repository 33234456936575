import React from 'react'
import { Box, Heading } from 'theme-ui'

/**
 * Shadow me to add your own content
 */

const styles = {
  box: {
    borderLeft: `5px solid`,
    borderLeftColor: `omegaLighter`,
    pl: 5,
    py: 2,
    my: 5
  },
  quote: {
    color: `omegaDark`,
    fontWeight: `body`
  },
  writer: {
    color: `omegaDark`,
    mb: 0
  }
}

export default () => (
  <Box sx={styles.box}>
    <Heading
      variant='h3'
      sx={styles.quote}
      style={{ fontSize: '1.1rem', letterSpacing: '0.45px' }}
    >
      defi.to
      网站希望通过不断输出DeFi概念解读、价值DeFi项目研究、热门DeFi项目图文教程等内容，帮助更多用户走进DeFi、了解DeFi、玩转DeFi，并逐渐成为DeFi爱好者传递海内外DeFi文化、连接项目与用户之间的首选桥梁。
    </Heading>
    {/* <Heading variant='h4' sx={styles.writer}>
      Henry C. Link
    </Heading> */}
  </Box>
)
